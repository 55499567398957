import { GameManager } from "./../../GameManager";
import { BattleManager } from "./BattleManager";
import { GameVars } from "../../GameVars";
import { MenuTitle } from "./gui/MenuTitle";
import { Score } from "./hud/Score";
import { GameConstants } from "../../GameConstants";
import { AudioManager } from "../../AudioManager";
import { getShare, sendTMAScore } from "../../server/server.api";

export class GameOverLayer extends Phaser.GameObjects.Container {

  constructor(scene: Phaser.Scene) {

    super(scene);

    if (BattleManager.engine.score > GameVars.gameData.highScore) {
      GameVars.gameData.highScore = BattleManager.engine.score;
      GameManager.writeGameData();
    }

    try {
      const message = {
        eventName: "sendScore",
        score: BattleManager.engine.score
      };
      // window.parent.postMessage(message, origin);
      // sendTMAScore(BattleManager.engine.score, 'EndlessSiegeGameBot').then(
      //   (result) => console.log("Current daily game result was successfully submitted " + result.body)
      // );
    } catch {
      console.log("Failed to send score.");
    }

    const background = new Phaser.GameObjects.Graphics(this.scene);
    background.fillStyle(0x000000, .5);
    background.fillRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);
    this.add(background);

    const scaledItemsContainer = new Phaser.GameObjects.Container(this.scene);
    scaledItemsContainer.x = GameConstants.GAME_WIDTH / 2;
    scaledItemsContainer.y = GameConstants.GAME_HEIGHT / 2;
    scaledItemsContainer.scaleY = GameVars.scaleY;
    this.add(scaledItemsContainer);

    // this is a black background with transparency
    const menuBackground = new Phaser.GameObjects.Graphics(this.scene);
    menuBackground.fillStyle(0x000000, .75);
    menuBackground.fillRoundedRect(-180, -200, 360, 400, 10);
    menuBackground.lineStyle(3, 0xacfffa);
    menuBackground.strokeRoundedRect(-180, -200, 360, 400, 10);
    scaledItemsContainer.add(menuBackground);

    // screen title
    const title = new MenuTitle(this.scene, GameVars.gameText[GameVars.gameData.language].GAME_OVER);
    title.setPosition(0, -200);
    scaledItemsContainer.add(title);

    const buttonContainer = new Phaser.GameObjects.Container(this.scene, 0, 130);
    scaledItemsContainer.add(buttonContainer);

    let scoreLabel = new Phaser.GameObjects.Text(this.scene, 0, -145, GameVars.gameText[GameVars.gameData.language].SCORE, {
      fontFamily: "Supercell",
      fontSize: "22px",
      color: "#FFE734"
    });
    scoreLabel.setOrigin(.5);
    scoreLabel.setStroke("#B35B20", 4);
    scoreLabel.setShadow(1, 2, "#700000", 0, true, false);
    scaledItemsContainer.add(scoreLabel);

    let score = new Score(this.scene);
    score.setPosition(-137, -95);
    scaledItemsContainer.add(score);

    scoreLabel = new Phaser.GameObjects.Text(this.scene, 0, -40, GameVars.gameText[GameVars.gameData.language].BEST_SCORE, {
      fontFamily: "Supercell",
      fontSize: "22px",
      color: "#FFE734"
    });
    scoreLabel.setOrigin(.5);
    scoreLabel.setStroke("#B35B20", 4);
    scoreLabel.setShadow(1, 2, "#700000", 0, true, false);
    scaledItemsContainer.add(scoreLabel);

    score = new Score(this.scene, GameVars.gameData.highScore);
    score.setPosition(-137, 15);
    scaledItemsContainer.add(score);

    const roundLabel = new Phaser.GameObjects.Text(this.scene, 0, 65, GameVars.gameText[GameVars.gameData.language].ROUND + GameVars.formatNumber(BattleManager.engine.round), {
      fontFamily: "Supercell",
      fontSize: "22px",
      color: "#FFFFFF"
    });
    roundLabel.setOrigin(.5);
    roundLabel.setStroke("#000000", 5);
    roundLabel.setShadow(3, 3, "#000000");
    scaledItemsContainer.add(roundLabel);

    // restart button
    const restartButton = new Phaser.GameObjects.Image(this.scene, 0, 10, "texture_atlas_1", "btn_yellow_wide");
    restartButton.setInteractive({ useHandCursor: true });
    restartButton.on("pointerover", () => {
      buttonContainer.setScale(1.025);
    });
    restartButton.on("pointerout", () => {
      buttonContainer.setScale(1);
    });
    restartButton.on("pointerdown", async () => {
      console.log("pointerdown");
      const shareResult = await getShare(window.Telegram.WebApp.initDataUnsafe.user.id)
      console.log("Result " + JSON.stringify(shareResult))
      this.onRestartDown(shareResult.body.id);
    });
    buttonContainer.add(restartButton);

    this.scene.tweens.add({
      targets: buttonContainer,
      scaleX: 1.035,
      scaleY: 1.035,
      ease: Phaser.Math.Easing.Cubic.InOut,
      duration: 350,
      yoyo: true,
      repeat: -1
    });

    const restartText = new Phaser.GameObjects.Text(this.scene, 0, 10, GameVars.gameText[GameVars.gameData.language].OK, {
      fontFamily: "Supercell",
      fontSize: "22px",
      color: "#FFFFFF"
    });
    restartText.setOrigin(.5);
    restartText.setStroke("#663333", 6);
    restartText.setShadow(3, 3, "#663333");
    buttonContainer.add(restartText);

    this.alpha = 0;

    this.scene.tweens.add({
      targets: this,
      alpha: 1,
      ease: Phaser.Math.Easing.Cubic.Out,
      duration: 500
    });
  }

  private onRestartDown(shareId: any): void {
    console.log("Share id: " + shareId);
    AudioManager.playSoundEffect("btn_click");

    window.Telegram.WebApp.shareMessage(shareId ,(isSent: boolean) => { 
      console.log("Callback result: " + isSent)
      if(isSent) GameManager.reset();
    })
  }
}
